import { useContext, useRef } from "react"
import styled from "styled-components"

import { useDay } from "@datepicker-react/hooks"

import { mColors } from "src/ui/colors"
import { DateRangePickerContext } from "src/ui/DateRangePicker/DateRangePickerContext"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

export function Day({
  dayLabel,
  date,
  backgroundColor,
}: {
  dayLabel: string
  date: Date
  backgroundColor?: string
}) {
  const dayRef = useRef(null)

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isStartDate,
    isEndDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DateRangePickerContext)

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  })

  if (!dayLabel) {
    return null
  }

  const getColorFn = getColor({
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
  })

  return (
    <Box
      $borderRadius={getBoxBorderRadius({
        isStartDate: isStartDate(date),
        isEndDate: isEndDate(date),
      })}
      $backgroundColor={backgroundColor}
    >
      <Button
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        disabled={disabledDate}
        ref={dayRef}
        color={getColorFn({
          selectedFirstOrLastColor: mColors.neutralLight,
          normalColor: mColors.textTertiary,
          selectedColor: mColors.textTertiary,
          rangeHoverColor: mColors.textTertiary,
          disabledColor: `${mColors.textInactive}70`,
        })}
        $background={getColorFn({
          selectedFirstOrLastColor: mColors.primary,
          normalColor: mColors.neutralLight,
          selectedColor: mColors.primaryLight,
          rangeHoverColor: mColors.primaryLight,
          disabledColor: mColors.neutralLight,
        })}
        $borderRadius={getButtonBorderRadius(isSelectedStartOrEnd)}
      >
        {dayLabel}
      </Button>
    </Box>
  )
}

function getButtonBorderRadius(isSelectedStartOrEnd: boolean) {
  return isSelectedStartOrEnd ? "100%" : "0%"
}

function getBoxBorderRadius({
  isStartDate,
  isEndDate,
}: {
  isStartDate: boolean
  isEndDate: boolean
}) {
  if (isStartDate) {
    return "100% 0% 0% 100%"
  } else if (isEndDate) {
    return "0% 100% 100% 0%"
  } else {
    return "0px"
  }
}

function getColor({
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  disabledDate,
}: {
  isSelected: boolean
  isSelectedStartOrEnd: boolean
  isWithinHoverRange: boolean
  disabledDate: boolean
}) {
  return function ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }: {
    selectedFirstOrLastColor: string
    normalColor: string
    selectedColor: string
    rangeHoverColor: string
    disabledColor: string
  }) {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor
    } else if (isSelected) {
      return selectedColor
    } else if (isWithinHoverRange) {
      return rangeHoverColor
    } else if (disabledDate) {
      return disabledColor
    } else {
      return normalColor
    }
  }
}

const Box = styled.div<{ $borderRadius: string; $backgroundColor?: string }>`
  background: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : mColors.primaryLight};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  aspect-ratio: 1;
  min-width: 45px;
`

const Button = styled.button<{
  $background: string
  disabled: boolean
  color: string
  $borderRadius: string
}>`
  padding: ${spacing.S};
  width: 100%;
  height: 100%;
  border: 0;
  color: ${(props) => props.color};
  background: ${(props) => props.$background};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: 11px;
  font-weight: ${fontWeight.semiBold};
  outline: none;
  border-radius: ${({ $borderRadius }) => $borderRadius};
`
