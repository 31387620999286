const DATE_FORMAT = "MMM d y"
const DATE_FORMAT_SAME_YEAR = "eee, MMM d"

const isSameYear = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) {
    return false
  }
  return startDate.getFullYear() === endDate.getFullYear()
}

export function getDatePickerTimeFormat(
  startDate: Date | null,
  endDate: Date | null
) {
  return isSameYear(startDate, endDate) ? DATE_FORMAT_SAME_YEAR : DATE_FORMAT
}
