import styled from "styled-components"

import { FirstDayOfWeek, useMonth } from "@datepicker-react/hooks"
import { intlFormat } from "date-fns"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { minimumGray } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

import { Day } from "./Day"

export function Month({
  year,
  month,
  firstDayOfWeek,
  dayBackgroundColor,
}: {
  year: number
  month: number
  firstDayOfWeek: FirstDayOfWeek
  dayBackgroundColor?: string
}) {
  const { preferred_language } = useGetUser()

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: (date) => {
      return intlFormat(date, { month: "long" }, { locale: preferred_language })
    },
    weekdayLabelFormat: (date) => {
      return intlFormat(
        date,
        { weekday: "short" },
        { locale: preferred_language }
      )
    },
  })

  return (
    <div>
      <MonthLabel>{monthLabel}</MonthLabel>

      <WeekDayLabels>
        {weekdayLabels.map((dayLabel) => (
          <div style={{ textAlign: "center" }} key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </WeekDayLabels>

      <Days>
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
                backgroundColor={dayBackgroundColor}
              />
            )
          }

          return <div key={index} />
        })}
      </Days>
    </div>
  )
}

const MonthLabel = styled.div`
  margin: 0.4rem 0 ${spacing.XL};
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
`

const WeekDayLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: ${fontWeight.semiBold};
  color: ${minimumGray};
  text-transform: capitalize;
`

const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  row-gap: 2px;
`
