import { createContext } from "react"

type StateType = {
  focusedDate: Date | null
  goToNextMonths: () => void
  goToPreviousMonths: () => void
  isDateBlocked: (date: Date) => boolean
  isDateFocused: (date: Date) => boolean
  isDateHovered: (date: Date) => boolean
  isDateSelected: (date: Date) => boolean
  isFirstOrLastSelectedDate: (date: Date) => boolean
  isStartDate: (date: Date) => boolean
  isEndDate: (date: Date) => boolean
  onDateFocus: (date: Date) => void
  onDateHover: (date: Date | null) => void
  onDateSelect: (date: Date) => void
}
const initialState: StateType = {
  focusedDate: null,
  goToNextMonths: () => {},
  goToPreviousMonths: () => {},
  isDateBlocked: () => false,
  isDateFocused: () => false,
  isDateHovered: () => false,
  isDateSelected: () => false,
  isFirstOrLastSelectedDate: () => false,
  isStartDate: () => false,
  isEndDate: () => false,
  onDateFocus: () => {},
  onDateHover: () => {},
  onDateSelect: () => {},
}
export const DateRangePickerContext = createContext(initialState)
